<template>
    <div>
        <Navbar page="Importar" link="/mapeamento" nameLink="Mapeamento de atividades de tratamento" video="https://www.youtube.com/watch?v=l_AcErNE8Vc" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <h2 class="text-2xl text-gray-900 font-semibold mb-5">
                Importar questionário de mapeamento de atividade
            </h2>
            <button v-if="!baseImportada" @click="$modal.show('gerarArquivoModelo')" class="inline-flex justify-center py-2 px-6 mb-5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                </svg> 
                Importar atividade 
            </button>
            <h3 v-if="baseImportada" class="text-sm text-gray-900 font-bold mb-2">
                Campos do arquivo (Cabeçalho):
            </h3>
            <div v-for="(key, idk) in keys" :key="idk" class="py-2 px-4 border w-160">
                <label class="text-sm font-medium">
                    {{ key.nome }}: <span :class="key.valido ? 'text-green-700': 'text-red-700'">{{ key.valido ? 'OK': 'Inválido'}}</span>
                </label>
            </div>
            <div v-for="(form, idx) in atividades" :key="idx" class="grid grid-cols-12 gap-4 my-2 mt-4">
                <div class="col-span-2">
                    <h2 class="block text-base font-medium">
                        Atividade: {{ idx + 1 }}
                    </h2>
                </div>
                <div v-if="form.tipo_responsavelQMA" class="col-span-12 md:col-span-3">
                    <label for="tipo_responsavelQMA" class="block text-sm font-medium">Tipo Responsável</label>
                    <select required v-model="form.tipo_responsavelQMA" name="tipo_responsavelQMA" id="tipo_responsavelQMA" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option value="consultor">Marcar consultor como responsável</option>
                        <option value="profissional">Profissional</option>
                        <option value="pessoa">Pessoas na empresa</option>
                    </select>
                </div>
                <div v-if="form.tipo_responsavelQMA === 'profissional'" class="col-span-12 md:col-span-3">
                    <label for="profissional_responsavelQMA" class="block text-sm font-medium">
                        Responsável pela atividade
                    </label>
                    <select required v-model="form.profissional_responsavelQMA" name="profissional_responsavelQMA" id="profissional_responsavelQMA" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option v-for="profissional in profissionais" :key="profissional._id" :value="profissional._id">{{ profissional.nome }}</option>
                    </select>
                </div>
                <div v-if="form.tipo_responsavelQMA === 'pessoa'" class="col-span-12 md:col-span-3">
                    <label for="responsavel" class="block text-sm font-medium">
                        Responsável pela atividade
                    </label>
                    <select v-model="form.pessoa_responsavelQMA" name="pessoa_responsavelQMA" id="pessoa_responsavelQMA" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
                    </select>
                </div>
                <div class="col-span-12"/>
                <div class="col-span-12 md:col-span-3">
                    <label for="name" class="block text-sm font-medium">
                        Nome da Atividade
                    </label>
                    <input v-model="form.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                </div>
                <div v-if="form.agente || formModel.agente" class="col-span-12 md:col-span-3">
                    <label for="agenteTratamento" class="block text-sm font-medium" :class="!form.agente && form.errs.indexOf('agente') !== -1 ? 'text-red-500' : ''">
                        Agente de tratamento
                    </label>
                    <select required v-model="form.agente" name="agenteTratamento" id="agenteTratamento" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" :class="!form.agente && form.errs.indexOf('agente') !== -1 ? 'border-red-500' : ''">
                        <option v-for="agenteTratamento in agentesTratamento" :key="agenteTratamento._id" :value="agenteTratamento._id">{{ agenteTratamento.nome }}</option>
                    </select> 
                </div>
                <div v-if="form.finalidade || formModel.finalidade" class="col-span-12 md:col-span-3">
                    <label for="finalidade" class="block text-sm font-medium" :class="!form.finalidade && form.errs.indexOf('finalidade') !== -1 ? 'border-red-500' : ''"> 
                        Finalidade da atividade
                    </label>
                    <select required v-model="form.finalidade" name="finalidade" id="finalidade" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" :class="!form.finalidade && form.errs.indexOf('finalidade') !== -1 ? 'border-red-500' : ''">
                        <option v-for="finalidade in finalidades" :key="finalidade._id" :value="finalidade._id">{{ finalidade.titulo }}</option>
                    </select>                   
                </div>
                <div v-if="form.setor || formModel.setor" class="col-span-12 md:col-span-3">
                    <label for="setor" class="block text-sm font-medium" :class="!form.setor && form.errs.indexOf('setor') !== -1 ? 'border-red-500' : ''"> 
                        Setor
                    </label>
                    <select v-model="form.setor" name="setor" id="setor" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" :class="!form.setor && form.errs.indexOf('setor') !== -1 ? 'border-red-500' : ''">
                        <option v-for="setor in setores" :key="setor._id" :value="setor._id">{{ setor.nome }}</option>
                    </select>
                </div>
                <div v-if="form.tipotratamentodados.length || formModel.tipotratamentodados" class="col-span-12 md:col-span-3">
                    <label for="tipotratamentodados" class="block text-sm font-medium" :class="!form.tipotratamentodados.length && form.errs.indexOf('tipotratamentodados') !== -1 ? 'text-red-500' : ''">  
                        Tipo de tratamento dos dados na atividade
                    </label>
                    <multiselect v-model="form.tipotratamentodados" :options="tratamentodados" :custom-label="customLabelTratamentos" :multiple="true" :close-on-select="false" :taggable="true" id="dado" placeholder="selecione os dados coletados" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />                 
                </div>
                <div v-if="form.dados.length || formModel.dados" class="col-span-12 md:col-span-3">
                    <label class="block text-sm font-medium" :class="!form.dados.length && form.errs.indexOf('dados') !== -1 ? 'text-red-500' : ''">  
                        Tipos de dados tratados nesta atividade
                    </label>
                    <multiselect v-model="form.dados" :options="dados" :multiple="true" :close-on-select="false" @input="onSelect" :custom-label="customLabel" :taggable="true" label="nome" trackBy="_id" id="dado" placeholder="selecione os dados coletados" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                </div>
                <div v-if="form.tipotitular.length || formModel.tipotitular" class="col-span-12 md:col-span-3">
                    <label for="tipotitular" class="block text-sm font-medium" :class="!form.tipotitular.length && form.errs.indexOf('tipotitular') !== -1 ? 'text-red-500' : ''">
                        Tipo titular dos dados
                    </label>
                    <multiselect v-model="form.tipotitular" :options="tipotitulares" :multiple="true" :close-on-select="false" :taggable="true" label="tipo" trackBy="_id" id="tipotitular" placeholder="selecione os tipos de titulares" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                </div>
                <div v-if="form.canais.length || formModel.canais" class="col-span-12 md:col-span-3">
                    <label for="canais" class="block text-sm font-medium" :class="!form.canais.length && form.errs.indexOf('canais') !== -1 ? 'text-red-500' : ''">
                        Canal de tratamento dos dados
                    </label>
                    <multiselect v-model="form.canais" :options="canais" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="canais" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                </div>
                <div v-if="form.armazenamento.length || formModel.armazenamento" class="col-span-12 md:col-span-3" :class="!form.armazenamento.length && form.errs.indexOf('armazenamentos') !== -1 ? 'bg-red-500' : ''">
                    <label for="armazenamento" class="block text-sm font-medium">
                        Local de armazenamento dos dados
                    </label>
                    <multiselect v-model="form.armazenamento" :options="armazenamentos" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="armazenamento" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                </div>
                <div v-if="form.prazoRetencao || form.tipoPrazoRetencao || formModel.prazoRetencao" class="col-span-12 md:col-span-3">
                    <label for="prazoRetencao" class="block text-sm font-medium" :class="!form.tipoPrazoRetencao && form.errs.indexOf('tipoPrazoRetencao') !== -1 ? 'text-red-500' : ''">
                        Tipo Prazo de Retenção
                    </label>
                    <select required v-model="form.tipoPrazoRetencao" name="status" id="status" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" :class="!form.tipoPrazoRetencao && form.errs.indexOf('tipoPrazoRetencao') !== -1 ? 'border-red-500' : ''">
                        <option value="Semanas">Semanas</option>
                        <option value="Dias">Dias</option>
                        <option value="Meses">Meses</option>
                        <option value="Anos">Anos</option>
                        <option value="Indeterminado">Indeterminado</option>
                    </select>
                    <label v-if="form.tipoPrazoRetencao !== 'Indeterminado'" for="prazoRetencao" class="block text-sm font-medium mt-2" :class="!form.prazoRetencao && form.errs.indexOf('prazoRetencao') !== -1 ? 'text-red-500' : ''">
                        Prazo de Retenção <span v-if="form.tipoPrazoRetencao">({{ form.tipoPrazoRetencao }})</span>
                    </label>
                    <input v-if="form.tipoPrazoRetencao !== 'Indeterminado'" v-model="form.prazoRetencao" type="number" name="prazoRetencao" id="prazoRetencao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl" :class="!form.prazoRetencao && form.errs.indexOf('prazoRetencao') !== -1 ? 'border-red-500' : ''"/>
                </div>
                <div v-if="form.formaDescarte || formModel.formaDescarte" class="col-span-12 md:col-span-3">
                    <label for="formaDescarte" class="block text-sm font-medium" :class="!form.formaDescarte && form.errs.indexOf('formaDescarte') !== -1 ? 'text-red-500' : ''">
                        Forma de descarte de dado
                    </label>
                    <textarea rows="6" v-model="form.formaDescarte" type="text" name="formaDescarte" id="formaDescarte" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl" :class="!form.formaDescarte && form.errs.indexOf('formaDescarte') !== -1 ? 'border-red-500' : ''"/>
                </div>
                <div v-if="form.qtd || form.frequencia || formModel.qtd" class="col-span-12 md:col-span-3">
                    <label for="qtd" class="block text-sm font-medium" :class="!form.qtd && form.errs.indexOf('qtd') !== -1 ? 'text-red-500' : ''"> 
                        Quantas vezes realiza a atividade
                    </label>
                    <input v-model="form.qtd" type="number" name="qtd" id="qtd" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl" :class="!form.qtd && form.errs.indexOf('qtd') !== -1 ? 'border-red-500' : ''">
                    <label for="frequencia" class="block text-sm font-medium mt-2" :class="!form.frequencia && form.errs.indexOf('frequencia') !== -1 ? 'border-red-500' : ''">
                        Frequência
                    </label>
                    <select required v-model="form.frequencia" name="frequencia" id="frequencia" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" :class="!form.frequencia && form.errs.indexOf('frequencia') !== -1 ? 'border-red-500' : ''">
                        <option value="diaria">Diária</option>
                        <option value="semanal">Semanal</option>
                        <option value="mensal">Mensal</option>
                        <option value="anual">Anual</option>
                    </select>
                </div>
                <div v-if="form.baselegal || formModel.baselegal" class="col-span-12 md:col-span-3" :class="!form.baselegal && form.errs.indexOf('baselegal') !== -1 ? 'text-red-500' : ''">
                    <label for="baselegal" class="block text-sm font-medium">
                        Base Legal utilizada
                    </label>
                    <select required v-model="form.baselegal" name="baselegal" id="baselegal" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" :class="!form.baselegal && form.errs.indexOf('baselegal') !== -1 ? 'border-red-500' : ''">
                        <option :value="null">Não sei informar</option>
                        <option v-for="baselegal in baselegais" :key="baselegal._id" :value="baselegal._id">{{ baselegal.nome }}</option>
                    </select>
                </div>
                <div v-if="form.legislacoes.length || formModel.legislacoes" class="col-span-12 md:col-span-3" :class="baselegais.length && form.baselegal && baselegais.find(el => el._id.toString() === form.baselegal.toString()).nome !== 'Cumprimento de obrigação legal ou regulatória' || $store.state.user.cliente ? 'opacity-50' : ''">
                    <label for="legislacoes" class="block text-sm font-medium" :class="!form.legislacoes.length && form.errs.indexOf('legislacoes') !== -1 ? 'text-red-500' : ''"> 
                        Legislações Aplicada
                    </label>
                    <multiselect :disabled="baselegais.length && form.baselegal && baselegais.find(el => el._id.toString() === form.baselegal.toString()).nome !== 'Cumprimento de obrigação legal ou regulatória'" v-model="form.legislacoes" :options="legislacoes" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="legislacoes" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                </div>
                <div v-if="form.medidasSeguranca.length || formModel.medidasSeguranca" class="col-span-12 md:col-span-3" :class="!form.medidasSeguranca.length && form.errs.indexOf('medidasSeguranca') !== -1 ? 'text-red-500' : ''">
                    <label for="medidasSeguranca" class="block text-sm font-medium">
                        Descreva as medidas de segurança existente nessa atividade
                    </label>
                    <multiselect v-model="form.medidasSeguranca" :options="medidasSeguranca" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="medidasSeguranca" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                </div>
                <div v-if="form.titularmenor || formModel.titularmenor" class="col-span-12 md:col-span-12">
                    <label for="titularmenor" class="block text-sm font-medium">
                        <input type="checkbox" v-model="form.titularmenor" class="rounded-sm" id="titularmenor">
                        <span class="ml-2">
                            Titular menor de 18, vulnerável ou idoso
                        </span>
                    </label>
                </div>
                <div class="col-span-12 md:col-span-12">
                    <button @click="atividades.splice(idx, 1)" class="text-white hover:bg-red-500 bg-red-700 text-sm rounded py-1 px-4 mx-1">
                        Remover
                    </button>
                </div>
                <div class="col-span-12 md:col-span-12">
                    <hr />
                </div>
            </div>
            <div class="grid grid-cols-2">
                <div class="px-5 py-3 text-left sm:px-6">
                </div>
                <div class="py-3 text-right">
                    <button @click="gerar()" v-if="atividades.length" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Gerar Atividades
                    </button>
                </div>
            </div>
            <modal name="gerarArquivoModelo" :adaptive="true" :height="'auto'" :width="750">
                <div class="px-2 py-5">
                    <h2 class="text-xl mb-2 font-bold mb-1"> Gerar arquivo modelo para importação</h2>
                    <a target= "_blank" rel="noreferrer" :href="`/dados_importacao.pdf`" class="block text-sm underline mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mb-1 w-5 h-5 inline">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                        </svg>
                        Informações sobre campos no arquivo/planilha modelo
                    </a>
                    <h2 class="text-sm text-blue-700 font-medium mb-1"> Selecione os campos que deseja importar da sua planilha para a planilha padrão da Fácil:</h2>
                    <button @click="selecionarTodos" class="inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-xs_3 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-indigo-400 mb-3">
                        Selecionar todos
                    </button>
                    <div class="grid grid-cols-12 gap-x-1 gap-y-3">
                        <div class="col-span-6 md:col-span-4 opacity-50">
                            <label for="responsavel" class="block text-sm font-medium">
                                <input type="checkbox" :disabled="true" checked class="rounded-sm" id="responsavel">
                                <span class="ml-2">
                                    Nome/Processo
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="responsavel" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.responsavel" class="rounded-sm" id="responsavel">
                                <span class="ml-2">
                                    Responsável
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="agente" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.agente" class="rounded-sm" id="agente">
                                <span class="ml-2">
                                    Agente de tratamento
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="finalidade" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.finalidade" class="rounded-sm" id="finalidade">
                                <span class="ml-2">
                                    Finalidade
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="setor" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.setor" class="rounded-sm" id="setor">
                                <span class="ml-2">
                                    Setor
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="tipotratamentodados" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.tipotratamentodados" class="rounded-sm" id="tipotratamentodados">
                                <span class="ml-2">
                                    Tipos de tratamentos de dados
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="dados" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.dados" class="rounded-sm" id="dados">
                                <span class="ml-2">
                                    Tipos de dados tratados
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="titulardados" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.tipotitular" class="rounded-sm" id="titulardados">
                                <span class="ml-2">
                                    Tipos de titulares de dados
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="canaisdados" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.canaisdados" class="rounded-sm" id="canaisdados">
                                <span class="ml-2">
                                    Canais de tratamento dos dados
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="qtd" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.qtd" class="rounded-sm" id="qtd">
                                <span class="ml-2">
                                    Quantas vezes realiza a atividade
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="titularmenor" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.titularmenor" class="rounded-sm" id="titularmenor">
                                <span class="ml-2">
                                    Titular menor ou idoso
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="baselegal" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.baselegal" class="rounded-sm" id="baselegal">
                                <span class="ml-2">
                                    Base legal
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="legislacoes" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.legislacoes" class="rounded-sm" id="legislacoes">
                                <span class="ml-2">
                                    Legislação
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="armazenamento" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.armazenamento" class="rounded-sm" id="armazenamento">
                                <span class="ml-2">
                                    Local de armazenamento
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="prazoRetencao" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.prazoRetencao" class="rounded-sm" id="prazoRetencao">
                                <span class="ml-2">
                                    Prazo de retenção
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="formaDescarte" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.formaDescarte" class="rounded-sm" id="formaDescarte">
                                <span class="ml-2">
                                    Forma de descarte de dados
                                </span>
                            </label>
                        </div>
                        <div class="col-span-6 md:col-span-4">
                            <label for="medidasSeguranca" class="block text-sm font-medium">
                                <input type="checkbox" v-model="formModel.medidasSeguranca" class="rounded-sm" id="medidasSeguranca">
                                <span class="ml-2">
                                    Medidas de segurança existente
                                </span>
                            </label>
                        </div>
                        <div class="col-span-12 md:col-span-12">
                            <a @click="$modal.hide('gerarArquivoModelo');$modal.show('importarModal');" :href="`${url_api}/v1/atividades/gerar-modelo/atividades?token=${$store.state.token}&responsavel=${formModel.responsavel}&agente=${formModel.agente}&finalidade=${formModel.finalidade}&setor=${formModel.setor}&tipotratamentodados=${formModel.tipotratamentodados}&dados=${formModel.dados}&tipotitular=${formModel.tipotitular}&canaisdados=${formModel.canaisdados}&qtd=${formModel.qtd}&titularmenor=${formModel.titularmenor}&baselegal=${formModel.baselegal}&legislacoes=${formModel.legislacoes}&armazenamento=${formModel.armazenamento}&prazoRetencao=${formModel.prazoRetencao}&formaDescarte=${formModel.formaDescarte}&medidasSeguranca=${formModel.medidasSeguranca}`" target="_blank" class="inline-flex justify-center py-1 px-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400">1. Arquivo modelo </a>
                            <button @click="$modal.hide('gerarArquivoModelo');$modal.show('importarModal');" class="inline-flex justify-center py-1 px-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400 ml-3">2. Importar Arquivo </button>
                        </div>
                    </div>
                </div>
            </modal>
            <modal name="importarModal" :adaptive="true" :height="'auto'">
                <div class="px-5 py-5">
                    <h2 class="text-xl mb-2 font-bold mb-2"> Importar base</h2>
                    <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <a target= "_blank" rel="noreferrer" :href="`/dados_importacao.html`" class="text-sm underline">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mb-1 w-5 h-5 inline">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>
                            Arquivo base informações importação
                        </a>
                    </div>
                    <div class="col-span-12">
                        <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" @vdropzone-sending="send" v-on:vdropzone-success="update">
                        </vue-dropzone>
                    </div>
                    </div>
                </div>
            </modal>
        </div>
    </div>
</template>
<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
  components: {
    vueDropzone
  },
  data() {
    return {
      loader: this.$loading.show({ container: null, canCancel: true }),
      baseImportada: false,
      formModel: {
        responsavel: false, agente: false, finalidade: false, setor: false,
        tipotratamentodados: false, dados: false, tipotitular: false, titulardados: false, canaisdados: false,
        qtd: false, frequencia: false, titularmenor: false, baselegal: false, legislacoes: false,
        armazenamento: false, tipoPrazoRetencao: false, prazoRetencao: false, formaDescarte: false,
        medidasSeguranca: false,
      },
      keys: [],
      atividades: [],
      finalidades: [],
      preatividades: [],
      preatividade_setor: null,
      agentesTratamento: [],
      setores: [],
      pessoas: [],
      profissionais: [],
      dados: [],
      tipotitulares: [],
      canais: [],
      baselegais: [],
      legislacoes: [],
      armazenamentos: [],
      empresas: [],
      medidasSeguranca: [],
      tratamentodados: [
          'coleta',                                                              
          'produção',                                                               
          'recepção',                                                               
          'classificação',                                                               
          'utilização',                                                               
          'acesso',                                                               
          'reprodução',                                                               
          'transmissão',                                                               
          'distribuição',                                                               
          'processamento',                                                               
          'arquivamento',                                                               
          'armazenamento',                                                               
          'eliminação',                                                               
          'avaliação ou controle da informação',                                                               
          'modificação',                                                               
          'comunicação',                                                               
          'transferência',                                                               
          'difusão ou extração'
      ],
      dropzoneOptions: this.$http.getDropzoneConfig(
        `v1/atividades/gerarPreview`,
        'post',
      {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: 'Clique ou arraste seu arquivo aqui',
        dictRemoveFile: 'Remover'
      })
    }
  },
  methods: {
    async start(){
        const listDados = await this.$http.post(`/v1/dados/list`, { all: true});
        this.dados = listDados.data.data;

        const listTitulares = await this.$http.post(`/v1/tipotitulares/list`, { all: true});
        this.tipotitulares = listTitulares.data.data;

        const listCanais = await this.$http.post(`/v1/canais/list`, { all: true});
        this.canais = listCanais.data.data;

        const listBaselegais = await this.$http.post(`/v1/baselegais/list`, { all: true});
        this.baselegais = listBaselegais.data.data;

        const listLegislacoes = await this.$http.post(`/v1/legislacoes/list`, { all: true});
        this.legislacoes = listLegislacoes.data.data;

        const listArmazenamento = await this.$http.post(`/v1/armazenamento/list`, { all: true});
        this.armazenamentos = listArmazenamento.data.data;

        const listSetores = await this.$http.post(`/v1/setores/list`, { all: true});
        this.setores = listSetores.data.data;

        const listfinalidades = await this.$http.post(`/v1/finalidades/list`, { all: true});
        this.finalidades = listfinalidades.data.data;

        const listMedidas = await this.$http.post(`/v1/medidasSeguranca/list`, { all: true});
        this.medidasSeguranca = listMedidas.data.data;

        const listAgentesTratamento = await this.$http.post(`/v1/agentes/list`, { all: true});
        this.agentesTratamento = listAgentesTratamento.data.data;

        const listPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true });
        this.pessoas = listPessoas.data.data;

        const profissionaisRef = await this.$http.post(`/v1/profissionais/listProfissionaisEmpresa`);  
        this.profissionais = profissionaisRef.data.data;

        this.loader.hide();
    },

    async gerar() {
        this.$confirm({
            title: 'Gerar atividades',
            message: `Gerar as atividades da lista ?`,
            button: {
                no: 'Não',
                yes: 'Sim',
            },
            callback: async confirm => {
                if(!confirm) return
                const req = await this.$http.post(`/v1/atividades/criarLista`, { atividades: this.atividades });
                 if (req.data.success) {
                    this.$vToastify.success("Geradas com sucesso!");
                    this.$router.push({path: `/mapeamento`});
                } else {
                    this.$vToastify.error(req.data.err);
                }
            }
        });
    },

    send(file, xhr, formData) {
        this.loader = this.$loading.show({ container: null, canCancel: true });
        formData.append('empresa', this.$store.state.empresa && this.$store.state.empresa._id ? this.$store.state.empresa._id : null);
    },

    async update(file, response) {
        if(!response.success) {
            this.$vToastify.error(response.err);
            this.loader.hide();
            return
        }
        if(!response.atividades.length) this.$vToastify.error("Arquivo inválido! Verificar campos utilizandos no arquivo modelo.");
        console.log("response.atividades", response.atividades);
        this.atividades = response.atividades; 
        this.keys = response.keys;
        this.$modal.hide('importarModal');
        this.loader.hide();
        this.baseImportada = true;
    },

    selecionarTodos() {
        this.formModel = {
        responsavel: true, agente: true, finalidade: true, setor: true,
        tipotratamentodados: true, dados: true, tipotitular: true, titulardados: true, canaisdados: true,
        qtd: true, frequencia: true, titularmenor: true, baselegal: true, legislacoes: true,
        armazenamento: true, tipoPrazoRetencao: true, prazoRetencao: true, formaDescarte: true,
        medidasSeguranca: true
      };
    },

    customLabel ({ nome, sensivel, critico })  {
        return `${nome}${sensivel ? ' - DADO SENSÍVEL': ''}${critico ? ' - DADO CRÍTICO': ''}`
    },
    
    customLabelTratamentos(str) {
        return str.replace(/^./, str[0].toUpperCase());
    },

    onSelect () {
        setTimeout(function() {
            const tags = document.getElementsByClassName('multiselect__tag');
            for(let i = 0; i < tags.length; i++){
                const tag = tags[i];
                if(tag.children[0].innerHTML.match('CRÍTICO')){
                    tag.style.backgroundColor = "#f0ad4e";
                }
                if(tag.children[0].innerHTML.match('SENSÍVEL')){
                    tag.style.backgroundColor = "#d9534f";
                }
            }
        }, 500)
    },
  },
  async beforeMount() {
    this.start();
  },
}
</script>